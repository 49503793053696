// react
import React from 'react';

// types
import type { FormEvent } from 'react';

const inputStyles = {
  marginTop: '8px',
  padding: '6px 8px',
};

const labelStyles = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '12px',
  minWidth: '50%',
  width: 'fit-content',
};

const sectionStyles = {
  margin: '32px 16px',
};

const Input = function ({
  form,
  handleInput,
  id,
}: {
  form: Record<string, string>;
  handleInput: (e) => void;
  id: string;
}) {
  return (
    <label htmlFor={id} style={labelStyles as any}>
      {id.toUpperCase()}
      <input
        id={id}
        name={id.toUpperCase()}
        type="text"
        value={form[id]}
        onInput={handleInput}
        style={inputStyles as any}
      />
    </label>
  );
};

const initialState = {
  tac: '',
  tran_code: 'SALE',
  cust_nbr: '9001',
  merch_nbr: '900156',
  dba_nbr: '1',
  terminal_nbr: '1',
  amount: '1.99',
  tran_nbr: '1',
  account_nbr: '4111111111111111',
  cvv2: '123',
  exp_date: '2512',
  redirect_url: 'https://dev.requestmyrefund.com/payment-success',
  invalid_redirect_url: 'https://dev.requestmyrefund.com/payment-failure',
  response_url: 'https://api.dev.protecht.com/api/internal/epx',
};

const EPX = function () {
  // state
  const [browserPostURL, setBrowserPostURL] = React.useState(
    'https://services.epxuap.com/browserpost/'
  );
  const [form, setForm] = React.useState(initialState);

  // functions
  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    const event = e.target as HTMLInputElement;

    setForm((prevState) => ({
      ...prevState,
      [event.id]: event.value,
    }));
  };

  return (
    <section style={sectionStyles as any}>
      <header>
        <h1>EPX Browser Post</h1>
      </header>
      <div style={{ maxWidth: '600px' }}>
        <label htmlFor="browser_post_url" style={labelStyles as any}>
          BROWSER_POST_URL
          <input
            id="browser_post_url"
            type="text"
            value={browserPostURL}
            onInput={(e) =>
              setBrowserPostURL((e.target as HTMLInputElement).value)
            }
            style={inputStyles as any}
          />
        </label>
        <form
          action={browserPostURL}
          method="post"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {Object.keys(initialState).map((id) => (
            <Input key={id} form={form} handleInput={handleInput} id={id} />
          ))}
          <button type="submit" style={{ width: 'fit-content' }}>
            Submit payment
          </button>
        </form>
      </div>
    </section>
  );
};

export default EPX;
