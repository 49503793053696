// react
import React, { useEffect, VFC } from 'react';

// packages
import CssBaseline from '@mui/material/CssBaseline';

// redux
import { getConfig } from 'store/modules/config/thunks';
import {
  selectConfigLoading,
  selectIsConfigStatusError,
  selectTheme,
} from 'store/modules/config/selectors';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHistory } from 'react-router-dom';

// styles
import { Theme, composeTheme, ThemeProvider } from 'styles';

// components
import App from 'App';
import HelmetProvider from 'HelmetProvider';
import Loader from 'components/Loader';

// types
import type { ProductInUrlType } from 'types';
import { FlowType } from 'store/modules/config/types';

const AppInitializer: VFC<{
  product: ProductInUrlType | null;
  client: string | null;
  type: FlowType;
}> = function ({ product, client, type }) {
  // styles
  const generateTheme = (theme?: Partial<Theme>) => composeTheme(theme);

  // redux state
  const configErrorStatus = useAppSelector(selectIsConfigStatusError);
  const configLoading = useAppSelector(selectConfigLoading);
  const configTheme = useAppSelector(selectTheme);

  // hooks
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (product) {
      dispatch(getConfig({ product, client, type }));
    }
  }, [dispatch, product, history, client, type]);

  useEffect(() => {
    if (
      configErrorStatus &&
      history.location.pathname !== '/' &&
      history.location.pathname !== '/epx-testing' &&
      history.location.pathname !== '/payment-success' &&
      history.location.pathname !== '/payment-failure'
    )
      history.push('/404');
  }, [dispatch, configErrorStatus, history]);

  if (configLoading && history.location.pathname !== '/') return <Loader />;

  return (
    <ThemeProvider theme={generateTheme(configTheme)}>
      <HelmetProvider>
        <CssBaseline />
        <App />
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default AppInitializer;
